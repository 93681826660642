import { all, takeLatest, takeEvery, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import {apiPlanOtaService, taskService} from "@iso/services";
import moment from "moment";

export function* getApiPlans() {
  yield takeLatest(actions.GET_LIST_API_PLAN_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      otaIds,
      planName,
      status,
      checkInDate,
      checkOutDate,
      periodDateStart,
      periodDateEnd,
      registeredPerson,
      registrationDate,
      updateStatus,
      typePlans,
      page,
      size
    } = payload;

    try {
      const response = yield call(apiPlanOtaService.getApiPlans, {
        storeIds,
        otaIds,
        planName,
        status,
        checkInDate,
        checkOutDate,
        periodDateStart,
        periodDateEnd,
        registeredPerson,
        registrationDate,
        updateStatus,
        typePlans,
        page,
        size
      });

      yield put({
        type: actions.GET_LIST_API_PLAN_SUCCESS,
        plans: response !== null && response !== undefined ? response.apiPlanOtas : null,
        total: response !== null && response !== undefined ? response.total : 0
      });
    } catch (e) {
      yield put({ type: actions.GET_LIST_API_PLAN_ERROR });
    }
  });
}

export function* getApiPlanDetail() {
  yield takeLatest(actions.GET_API_PLAN_DETAIL_REQUEST, function* ({payload}) {
    const {resolve, reject, apiPlanId} = payload;
    try {
      let response = null;
      if (apiPlanId !== null && apiPlanId !== undefined) {
        response = yield call(apiPlanOtaService.getApiPlanDetail, {
          apiPlanId
        })
      }
      resolve(response)
      let otaFieldContent = {};
      if (response !== null && response !== undefined && response.apiPlanOtas !== null && response.apiPlanOtas !== undefined) {
        response.apiPlanOtas.map((apiPlanOta) => {
          let keyName = apiPlanOta.otaId;
          let fieldContents = JSON.parse(apiPlanOta.fieldContents);
          fieldContents.apiRoomOtas = apiPlanOta.apiRoomOtas;
          fieldContents.imageStorage = apiPlanOta.imageStorage;
          fieldContents.imageUrl = apiPlanOta.imageUrl;
          fieldContents.apiPlanOtaId = apiPlanOta.apiPlanOtaId;
          fieldContents.sitePlanId = apiPlanOta.sitePlanId;
          fieldContents.syncStatus = apiPlanOta.syncStatus;
          fieldContents.updateStatus = apiPlanOta.updateStatus;
          fieldContents.typePlan = apiPlanOta.typePlan;
          fieldContents.syncErrorContent =
              apiPlanOta.syncErrorContent !== null && apiPlanOta.syncErrorContent !== undefined ? apiPlanOta.syncErrorContent.trim() : '';
          fieldContents.codeStatus = apiPlanOta.codeStatus;
          let listDateConvert = [
              'postPeriodDateFrom',
              'postPeriodDateTo',
              'implementPeriodDateFrom',
              'implementPeriodDateTo',
              'registrationPeriodSetting',
              'settingPublicationPeriod',
          ];
          convertDateFieldsToMoment(fieldContents, listDateConvert);

          Object.assign(otaFieldContent, { [keyName]: fieldContents });
        });
      }

      if (otaFieldContent !== null && otaFieldContent !== undefined && Object.keys(otaFieldContent).length > 0) {
        response.otaFieldContent = otaFieldContent;
      }

      yield put({
        type: actions.GET_API_PLAN_DETAIL_SUCCESS,
        plan: response
      });
      resolve({});
    } catch (e) {
      yield put({type: actions.GET_API_PLAN_DETAIL_ERROR});
      reject(e)
    }
  })
}

const convertDateFieldsToMoment = (fieldContents, listDateConvert) => {
  listDateConvert.forEach(field => {
    let dateArray = fieldContents[field];
    if (Array.isArray(dateArray) && dateArray.length >= 2) {
      fieldContents[field] = [
        moment(dateArray[0]),
        moment(dateArray[1])
      ];
    }
  });
};

export function* createApiPlan() {
  yield takeLatest(actions.ADD_API_PLAN_REQUEST, function* ({payload}) {
    const { apiPlanData, resolve, reject } = payload;
    try {
      const response = yield call(apiPlanOtaService.createApiPlan,{
        ...apiPlanData,
        resolve,
        reject
      });
      yield put({
        type: actions.ADD_API_PLAN_SUCCESS,
        apiPlanId: response
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.ADD_API_PLAN_ERROR,
        error: e.message,
      });
      yield reject(e);
    }
  })
}

export function* updateApiPlan() {
  yield takeLatest(actions.UPDATE_API_PLAN_REQUEST, function* ({payload}) {
    const { apiPlanData, resolve, reject } = payload;
    try {
      const response = yield call(apiPlanOtaService.updateApiPlan,{
        ...apiPlanData,
        resolve,
        reject
      });
      yield put({
        type: actions.UPDATE_API_PLAN_SUCCESS,
        apiPlanId: response
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.UPDATE_API_PLAN_ERROR,
        error: e.message,
      });
      yield reject(e);
    }
  })
}

export function* deleteApiPlanOta() {
  yield takeLatest(actions.DELETE_API_PLAN_OTA_REQUEST, function* ({payload}) {
    const { apiPlanOtaId, apiPlanId, resolve, reject } = payload;
    try {
      const response = yield call(apiPlanOtaService.deleteApiPlanOta, {
        apiPlanOtaId,
        apiPlanId,
        resolve,
        reject
      });
      yield resolve(response);
    } catch (e) {
      yield reject(e);
    }
  })
}

export function* syncDataPlanToSite() {
  yield takeLatest(actions.SYNC_DATA_PLAN_OTA_REQUEST, function* ({payload}) {
    const { apiPlanOtaId, resolve, reject } = payload;
    try {
      const response = yield call(apiPlanOtaService.syncDataPlanToSite, {
        apiPlanOtaId,
        resolve,
        reject
      });
      yield resolve(response);
    } catch (e) {
      yield reject(e);
    }
  })
}

function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER_REQUEST, function* ({ payload }) {
    const { list_role } = payload;
    try {
      const [assignees] = yield all([
        call(taskService.getAssignees, { list_role }),
      ]);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        listUser: assignees ? assignees.accounts : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_USER_ERROR,
        error: e.message,
      });
    }
  });
}

function* getSyncPlanStatus() {
  yield takeEvery(actions.GET_SYNC_PLAN_STATUS_REQUEST, function* ({ payload }) {
    const {
      apiPlanId,
    } = payload;
    try {
      const response = yield call(apiPlanOtaService.getSyncPlanStatus, {
        apiPlanId
      });
      yield put({
        type: actions.GET_SYNC_PLAN_STATUS_SUCCESS,
        syncPlanStatus: response ?? [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_SYNC_PLAN_STATUS_ERROR,
        error: e.message,
      });
    }
  });
}

function* updateDataFromSite() {
  yield takeEvery(actions.UPDATE_DATA_PLAN_REQUEST, function* ({payload}) {
    const {apiPlanOtaId, resolve, reject} = payload;
    try {
      const response = yield call(apiPlanOtaService.updateDataFromSite, {
        apiPlanOtaId,
        resolve,
        reject
      })
      yield put({
        type: actions.UPDATE_DATA_PLAN_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.UPDATE_DATA_PLAN_ERROR,
      });
      yield reject(e);
    }
  })
}

function* crawlAllPlan() {
  yield takeEvery(actions.CRAWL_ALL_PLAN_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiPlanOtaService.crawlAllPlan, {
        storeId,
        otaId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ALL_PLAN_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ALL_PLAN_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* updateMultipleFromSite() {
  yield takeEvery(actions.UPDATE_MULTIPLE_PLAN_REQUEST, function* ({payload}) {
      const { listPlanOtaUpdate, resolve, reject } = payload;
    try {
      const response = yield call(apiPlanOtaService.updateMultipleFromSite, {
        listPlanOtaUpdate,
        resolve,
        reject
      })
      yield put({
        type: actions.UPDATE_MULTIPLE_PLAN_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.UPDATE_MULTIPLE_PLAN_ERROR,
      });
      yield reject(e);
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getApiPlans)]);
  yield all([fork(getApiPlanDetail)]);
  yield all([fork(createApiPlan)]);
  yield all([fork(updateApiPlan)]);
  yield all([fork(deleteApiPlanOta)]);
  yield all([fork(syncDataPlanToSite)]);
  yield all([fork(getListUser)]);
  yield all([fork(getSyncPlanStatus)]);
  yield all([fork(updateDataFromSite)]);
  yield all([fork(crawlAllPlan)]);
  yield all([fork(updateMultipleFromSite)]);
}
