const actions = {
    GET_CRAWL_ROOM_REQUEST: "CRAWL_ROOM/GET_CRAWL_ROOM_REQUEST",
    GET_CRAWL_ROOM_SUCCESS: "CRAWL_ROOM/GET_CRAWL_ROOM_SUCCESS",
    GET_CRAWL_ROOM_ERROR: "CRAWL_ROOM/GET_CRAWL_ROOM_ERROR",

    CRAWL_ROOM_REQUEST: "CRAWL_ROOM/CRAWL_ROOM_REQUEST",
    CRAWL_ROOM_SUCCESS: "CRAWL_ROOM/CRAWL_ROOM_SUCCESS",
    CRAWL_ROOM_ERROR: "CRAWL_ROOM/CRAWL_ROOM_ERROR",

    GET_LIST_USER_REQUEST: "CRAWL_ROOM/GET_LIST_USER_REQUEST",
    GET_LIST_USER_SUCCESS: "CRAWL_ROOM/GET_LIST_USER_SUCCESS",
    GET_LIST_USER_ERROR: "CRAWL_ROOM/GET_LIST_USER_ERROR",

    CRAWL_ROOM_ALL_REQUEST: "CRAWL_ROOM/CRAWL_ROOM_ALL_REQUEST",
    CRAWL_ROOM_ALL_SUCCESS: "CRAWL_ROOM/CRAWL_ROOM_ALL_SUCCESS",
    CRAWL_ROOM_ALL_ERROR: "CRAWL_ROOM/CRAWL_ROOM_ALL_ERROR",

    CRAWL_ROOM_DETAILS_REQUEST: "CRAWL_ROOM/CRAWL_ROOM_DETAILS_REQUEST",
    CRAWL_ROOM_DETAILS_SUCCESS: "CRAWL_ROOM/CRAWL_ROOM_DETAILS_SUCCESS",
    CRAWL_ROOM_DETAILS_ERROR: "CRAWL_ROOM/CRAWL_ROOM_DETAILS_ERROR",

    getListCrawlRoom: (payload) => ({ type: actions.GET_CRAWL_ROOM_REQUEST, payload }),

    crawlRoom: (payload) => ({ type: actions.CRAWL_ROOM_REQUEST, payload }),

    getListUser: (payload) => ({ type: actions.GET_LIST_USER_REQUEST, payload }),

    crawlRoomAll: (payload) => ({ type: actions.CRAWL_ROOM_ALL_REQUEST, payload }),

    crawlRoomDetails: (payload) => ({ type: actions.CRAWL_ROOM_DETAILS_REQUEST, payload }),
};

export default actions;
