import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class ApiRoomService extends BaseService {
    getRooms = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.ROOM_SEARCH, {
            ...data,
        });
    };

    createRoom = (data) => {
        return this.axios.post(API_ENDPOINTS.CREATE_ROOM, data);
    };

    fetchRoom = ({ apiRoomId }) => {
        return this.axios.get(API_ENDPOINTS.ROOM_DETAIL_UPDATE.replace("{apiRoomId}", apiRoomId));
    };

    updateRoom = ({ data }) => {
        const { apiRoomId } = data;
        return this.axios.put(
            API_ENDPOINTS.ROOM_DETAIL_UPDATE.replace("{apiRoomId}", apiRoomId),
            {
                ...data,
            }
        );
    };

    deleteApiRoomOta = (data) => {
        return this.axios. delete(API_ENDPOINTS.DELETE_API_ROOM_OTA.replace("{apiRoomOtaId}", data.apiRoomOtaId).replace("{apiRoomId}", data.apiRoomId));
    }

    syncDataToSite = (data) => {
        return this.axios.post(API_ENDPOINTS.SYNC_ROOM_OTA.replace("{apiRoomOtaId}", data.apiRoomOtaId));
    };

    updateDataFromSite = (data) => {
        return this.axios.post(API_ENDPOINTS.UPDATE_ROOM_OTA.replace("{apiRoomOtaId}", data.apiRoomOtaId));
    };
}
