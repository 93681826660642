import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import { apiRoomService } from "@iso/services";
import actions from "@iso/redux/apiRoom/actions";

function* getRooms() {
  yield takeEvery(actions.GET_ROOM_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      roomName,
      otaIds,
      updateStatus,
      typeRooms,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiRoomService.getRooms, {
        storeIds,
        roomName,
        otaIds,
        updateStatus,
        typeRooms,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_ROOM_SUCCESS,
        apiRoomOtas: response ? response.apiRoomOtas : [],
        total: response ? response.total : 0
      });
      yield resolve(response.apiRoomOtas);
    } catch (e) {
      yield put({
        type: actions.GET_ROOM_ERROR,
        error: e.message,
      });
    }
  });
}

function* createRoom() {
  yield takeEvery(actions.CREATE_ROOM_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(apiRoomService.createRoom, {...data});
      yield put({
        type: actions.CREATE_ROOM_SUCCESS,
      });
      resolve(response);
    } catch (error) {
      yield put({
        type: actions.CREATE_ROOM_ERROR,
        error: error.response,
      });
      reject({});
    }
  });
}

function* getRoom() {
  yield takeEvery(actions.GET_ROOM_DETAIL_REQUEST, function* ({ payload }) {
    const { resolve, reject, apiRoomId } = payload;
    try {
      const rooms = yield call(apiRoomService.fetchRoom, { apiRoomId });
      const room = convertDataRoom(rooms)
      resolve(rooms);
      yield put({
        type: actions.GET_ROOM_DETAIL_SUCCESS,
        room: room,
      });
    } catch (error) {
      reject(error)
      yield put({
        type: actions.GET_ROOM_DETAIL_ERROR,
        error: error.response,
      });
    }
  });
}

function* deleteApiRoomOta() {
  yield takeEvery(actions.DELETE_API_ROOM_OTA_REQUEST, function* ({payload}) {
    const {apiRoomOtaId, apiRoomId, resolve, reject} = payload;
    try {
      const response = yield call(apiRoomService.deleteApiRoomOta, {
        apiRoomOtaId,
        apiRoomId,
        resolve,
        reject
      })
      resolve(response);
    } catch (e) {
      reject(e);
    }
  })
}

function* syncDataToSite() {
  yield takeEvery(actions.SYNC_DATA_ROOM_REQUEST, function* ({payload}) {
    const {apiRoomOtaId, resolve, reject} = payload;
    try {
      const response = yield call(apiRoomService.syncDataToSite, {
        apiRoomOtaId,
        resolve,
        reject
      })
      resolve(response);
    } catch (e) {
      reject(e);
    }
  })
}

function* updateDataFromSite() {
  yield takeEvery(actions.UPDATE_DATA_ROOM_REQUEST, function* ({payload}) {
    const {apiRoomOtaId, resolve, reject} = payload;
    try {
      const response = yield call(apiRoomService.updateDataFromSite, {
        apiRoomOtaId,
        resolve,
        reject
      })
      resolve(response);
    } catch (e) {
      reject(e);
    }
  })
}

function* reloadRoom() {
  yield takeEvery(actions.RELOAD_ROOM_REQUEST, function* ({ payload }) {
    const { apiRoomId , resolve, reject} = payload;
    try {
      const rooms = yield call(apiRoomService.fetchRoom, { apiRoomId });

      const room = convertDataRoom(rooms)
      yield put({
        type: actions.RELOAD_ROOM_SUCCESS,
        room: room,
      });
      resolve(room)
    } catch (error) {
      yield put({
        type: actions.RELOAD_ROOM_ERROR,
        error: error.response,
      });
      reject(error)
    }
  });
}

const convertDataRoom = (rooms) => {
  const room = {
    'apiRoomId': null,
    'storeId': null,
    'apiRoomOtas': {},
    'otaId': []
  };
  rooms.map((r) => {
    room.apiRoomId = r.apiRoomId
    room.storeId = r.storeId
    let keyName = r.otaId;
    const apiRoomOtas = {
      'apiRoomOtaId': r.apiRoomOtaId,
      'otaId': r.otaId,
      'siteRoomId': r.siteRoomId,
      'fieldContents': JSON.parse(r.fieldContents),
      'syncStatus': r.syncStatus,
      'updateStatus': r.updateStatus,
      'typeRoom': r.typeRoom,
      'lastErrorId': r.lastErrorId,
      'syncErrorContent': r.syncErrorContent,
      'imageStorage': r.imageStorage,
      'imageUrl': r.imageUrl,
    };
    Object.assign(room.apiRoomOtas, { [keyName]: apiRoomOtas });
    room.otaId.push(r.otaId)
  })

  return room;
}

export default function* rootSaga() {
  yield all([
    fork(getRooms),
    fork(createRoom),
    fork(getRoom),
    fork(deleteApiRoomOta),
    fork(syncDataToSite),
    fork(reloadRoom),
    fork(updateDataFromSite),
  ]);
}
