const actions = {
  GET_DATA_HELP_CHAIN_REQUEST: "GET_DATA_HELP_CHAIN_REQUEST",
  GET_DATA_HELP_CHAIN_SUCCESS: "GET_DATA_HELP_CHAIN_SUCCESS",

  GET_DATA_HELP_STORE_REQUEST: "GET_DATA_HELP_STORE_REQUEST",
  GET_DATA_HELP_STORE_SUCCESS: "GET_DATA_HELP_STORE_SUCCESS",

  GET_DATA_HELP_ERROR: "GET_DATA_HELP_ERROR",

  GET_FILE_REQUEST: "HELP/GET_FILE_REQUEST",
  GET_FILE_SUCCESS: "HELP/GET_FILE_SUCCESS",
  GET_FILE_ERROR: "HELP/GET_FILE_ERROR",

  GET_AGGREGATE_REQUEST: "GET_AGGREGATE_REQUEST",
  GET_AGGREGATE_SUCCESS: "GET_AGGREGATE_SUCCESS",
  GET_AGGREGATE_ERROR: "GET_AGGREGATE_ERROR",

  GET_FILE_REPORT_REQUEST: "GET_FILE_REPORT_REQUEST",
  GET_FILE_REPORT_SUCCESS: "GET_FILE_REPORT_SUCCESS",
  GET_FILE_REPORT_ERROR: "GET_FILE_REPORT_ERROR",

  GET_RANKING_CANDEO_AGGREGATE_REQUEST: "GET_RANKING_CANDEO_AGGREGATE_REQUEST",
  GET_RANKING_CANDEO_AGGREGATE_SUCCESS: "GET_RANKING_CANDEO_AGGREGATE_SUCCESS",
  GET_RANKING_CANDEO_AGGREGATE_ERROR: "GET_RANKING_CANDEO_AGGREGATE_ERROR",

  GET_FILE_RANKING_CANDEO_REPORT_REQUEST: "GET_FILE_RANKING_CANDEO_REPORT_REQUEST",
  GET_FILE_RANKING_CANDEO_REPORT_SUCCESS: "GET_FILE_RANKING_CANDEO_REPORT_SUCCESS",
  GET_FILE_RANKING_CANDEO_REPORT_ERROR: "GET_FILE_RANKING_CANDEO_REPORT_ERROR",

  GET_RANKING_AGGREGATE_REQUEST: "GET_RANKING_AGGREGATE_REQUEST",
  GET_RANKING_AGGREGATE_SUCCESS: "GET_RANKING_AGGREGATE_SUCCESS",
  GET_RANKING_AGGREGATE_ERROR: "GET_RANKING_AGGREGATE_ERROR",

  GET_FILE_RANKING_REPORT_REQUEST: "GET_FILE_RANKING_REPORT_REQUEST",
  GET_FILE_RANKING_REPORT_SUCCESS: "GET_FILE_RANKING_REPORT_SUCCESS",
  GET_FILE_RANKING_REPORT_ERROR: "GET_FILE_RANKING_REPORT_ERROR",

  GET_SOLARE_AGGREGATE_REQUEST: "GET_SOLARE_AGGREGATE_REQUEST",
  GET_SOLARE_AGGREGATE_SUCCESS: "GET_SOLARE_AGGREGATE_SUCCESS",
  GET_SOLARE_AGGREGATE_ERROR: "GET_SOLARE_AGGREGATE_ERROR",

  GET_FILE_SOLARE_REPORT_REQUEST: "GET_FILE_SOLARE_REPORT_REQUEST",
  GET_FILE_SOLARE_REPORT_SUCCESS: "GET_FILE_SOLARE_REPORT_SUCCESS",
  GET_FILE_SOLARE_REPORT_ERROR: "GET_FILE_SOLARE_REPORT_ERROR",

  GET_ORIENTAL_AGGREGATE_REQUEST: "GET_ORIENTAL_AGGREGATE_REQUEST",
  GET_ORIENTAL_AGGREGATE_SUCCESS: "GET_ORIENTAL_AGGREGATE_SUCCESS",
  GET_ORIENTAL_AGGREGATE_ERROR: "GET_ORIENTAL_AGGREGATE_ERROR",

  GET_FILE_ORIENTAL_REPORT_REQUEST: "GET_FILE_ORIENTAL_REPORT_REQUEST",
  GET_FILE_ORIENTAL_REPORT_SUCCESS: "GET_FILE_ORIENTAL_REPORT_SUCCESS",
  GET_FILE_ORIENTAL_REPORT_ERROR: "GET_FILE_ORIENTAL_REPORT_ERROR",

  getStoreData: (payload) => ({
    type: actions.GET_DATA_HELP_STORE_REQUEST,
    payload,
  }),

  getChainData: (payload) => ({
    type: actions.GET_DATA_HELP_CHAIN_REQUEST,
    payload,
  }),

  getFileRequest: () => ({
    type: actions.GET_FILE_REQUEST,
  }),

  createAggregateData: (payload) => ({
    type: actions.GET_AGGREGATE_REQUEST,
    payload,
  }),

  getFileReport: (payload) => ({
    type: actions.GET_FILE_REPORT_REQUEST,
    payload,
  }),

  createRankingCandeoAggregateData: (payload) => ({
    type: actions.GET_RANKING_CANDEO_AGGREGATE_REQUEST,
    payload,
  }),

  getFileRankingCandeoReport: (payload) => ({
    type: actions.GET_FILE_RANKING_CANDEO_REPORT_REQUEST,
    payload,
  }),

  createRankingAggregateData: (payload) => ({
    type: actions.GET_RANKING_AGGREGATE_REQUEST,
    payload,
  }),

  getFileRankingReport: (payload) => ({
    type: actions.GET_FILE_RANKING_REPORT_REQUEST,
    payload,
  }),

  createSolareAggregateData: (payload) => ({
    type: actions.GET_SOLARE_AGGREGATE_REQUEST,
    payload,
  }),

  getFileSolareReport: (payload) => ({
    type: actions.GET_FILE_SOLARE_REPORT_REQUEST,
    payload,
  }),

  createOrientalAggregateData: (payload) => ({
    type: actions.GET_ORIENTAL_AGGREGATE_REQUEST,
    payload,
  }),

  getFileOrientalReport: (payload) => ({
    type: actions.GET_FILE_ORIENTAL_REPORT_REQUEST,
    payload,
  }),
};

export default actions;
