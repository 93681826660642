import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class ApiCrawlRoomService extends BaseService {
    getListCrawlRoom = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_ROOM_SEARCH, {
            ...data,
        });
    };

    crawlRoom = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_ROOM, {
            ...data,
        });
    };

    crawlRoomAll = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_ROOM_ALL, {
            ...data,
        });
    };
    
    crawlRoomDetails = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_ROOM_DETAILS, {
            ...data,
        });
    };
}