export const colLeft = {
  xs: { span: 24, offset: 0 },
  lg: { span: 12, offset: 0 },
};

export const colSingleLeft = {
  xs: { span: 24, offset: 0 },
};

export const colRight = {
  xs: { span: 24, offset: 0 },
  lg: { span: 12, offset: 0 },
};

export const itemHorizonFullLabel = {
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 },
};

export const itemLeftLabel = {
  labelCol: { lg: 8, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

export const itemSingleLeftLabel = {
  labelCol: { lg: 4, xs: 24 },
  wrapperCol: { lg: 6, xs: 24 },
};

export const itemSingleFullLabel = {
  labelCol: { lg: 4, xs: 24 },
  wrapperCol: { lg: 20, xs: 24 },
};

export const itemRightLabel = {
  labelCol: { lg: { span: 8, offset: 4 }, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

export const colFull = {
  xs: { span: 24, offset: 0 },
  lg: { span: 24, offset: 0 },
};

export const itemFullLabel = {
  labelCol: { lg: 4, xs: 24 },
  wrapperCol: { lg: 20, xs: 24 },
};

export const colMid = {
  xs: { span: 24, offset: 11 },
  lg: { span: 24, offset: 11 },
};

/** START Style input for API **/
export const colSingleUnit = {
  xs: { span: 24 },
  lg: { span: 10}
};
export const inputColLeft = {
  labelCol: { lg: 9, xs: 24 },
  wrapperCol: { lg: 13, xs: 24 }
};
export const inputColRight = {
  labelCol: { lg: 9, xs: 24 },
  wrapperCol: { lg: 13, xs: 24 }
};
export const customRadioGroupFullRow = {
  labelCol : { lg: 4, xs: 24 },
  wrapperCol: { lg: 20, xs: 24 },
}
export const customCheckboxGroupFullRow = {
  labelCol : { lg: 4, xs: 24 },
  wrapperCol: { lg: 16, xs: 24 },
}

export const colFullApi = {
  xs: { span: 24, offset: 0 },
  lg: { span: 24, offset: 0 },
};

export const itemLeftLabelApi = {
  labelCol: { lg: 8, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

export const itemLeftLabelFullApi = {
  labelCol: { lg: 4, xs: 24 },
  wrapperCol: { lg: 18, xs: 24 },
};

export const itemWithLabelApi = {
  labelCol: { lg: 6, xs: 24 },
  wrapperCol: { lg: 12, xs: 24 },
};

export const itemCheckBoxApi = {
  labelCol: { lg: 0, xs: 24 },
  wrapperCol: { lg: 24, xs: 24 },
};

export const colLeftApi = {
  xs: { span: 24, offset: 0 },
  lg: { span: 12, offset: 0 },
};

export const colLeftInputWithLabelApi = {
  xs: { span: 24, offset: 0 },
  lg: { span: 16, offset: 0 },
};

export const colInputLeftApi = {
  xs: { span: 24, offset: 0 },
  lg: { span: 8, offset: 0 },
};

export const leftLabelFullItem = {
  labelCol: { lg: 4, xs: 24 },
  wrapperCol: { lg: 20, xs: 24 },
};

/** END Style input for API **/