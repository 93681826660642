import actions from "./actions";

const initialState = {
  error: null,
  loading: false,
  plans: [],
  total: 0,
  plan: {},
  apiPlanId: null,
  listUser: [],
  syncPlanStatus: [],
  loadingSyncStatus: false,
  crawlAllLoading: false,
  uploadMultiple: false,
};

export default function crawlPlanSaleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LIST_API_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_LIST_API_PLAN_SUCCESS:
      return {
        ...state,
        plans: action.plans,
        total: action.total,
        loading: false
      };
    case actions.GET_LIST_API_PLAN_ERROR:
      return {
        ...state,
        loading: false
      };
    case actions.GET_API_PLAN_DETAIL_REQUEST:
      return {
        ...state
      }
    case actions.GET_API_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        plan: action.plan
      }
    case actions.GET_API_PLAN_DETAIL_ERROR:
      return {
        ...state
      }
    case actions.ADD_API_PLAN_REQUEST:
      return {
        ...state
      }
    case actions.ADD_API_PLAN_SUCCESS:
      return {
        ...state,
        apiPlanId: action.apiPlanId
      }
    case actions.ADD_API_PLAN_ERROR:
      return {
        ...state
      }
    case actions.SYNC_DATA_PLAN_OTA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.SYNC_DATA_PLAN_OTA_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actions.SYNC_DATA_PLAN_OTA_ERROR:
      return {
        ...state,
        loading: false
      };
    case actions.GET_LIST_USER_REQUEST:
      return {
        ...state,
      };
    case actions.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.listUser,
      };
    case actions.GET_LIST_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_SYNC_PLAN_STATUS_REQUEST:
      return {
        ...state,
        loadingSyncStatus: true,
      };
    case actions.GET_SYNC_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        syncPlanStatus: action.syncPlanStatus,
        loadingSyncStatus: false,
      };
    case actions.GET_SYNC_PLAN_STATUS_ERROR:
      return {
        ...state,
        error: action.error,
        loadingSyncStatus: false,
      };
    case actions.UPDATE_DATA_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_DATA_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_DATA_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.CRAWL_ALL_PLAN_REQUEST:
      return {
        ...state,
        crawlAllLoading: true,
      };
    case actions.CRAWL_ALL_PLAN_SUCCESS:
      return {
        ...state,
        crawlAllLoading: false,
      };
    case actions.CRAWL_ALL_PLAN_ERROR:
      return {
        ...state,
        crawlAllLoading: false,
        error: action.error,
      };
    case actions.UPDATE_MULTIPLE_PLAN_REQUEST:
      return {
        ...state,
        uploadMultiple: true,
      };
    case actions.UPDATE_MULTIPLE_PLAN_SUCCESS:
      return {
        ...state,
        uploadMultiple: false,
      };
    case actions.UPDATE_MULTIPLE_PLAN_ERROR:
      return {
        ...state,
        uploadMultiple: false,
        error: action.error,
      };
    default:
      return state;
  }
}
