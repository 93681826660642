export const ROLES = {
  ADMIN: "ROLE_ADMIN",
  SUBADMIN: "ROLE_SUBADMIN",
  USER: "ROLE_USER",
  CHAIN: "ROLE_CHAIN",
  STORE: "ROLE_STORE",
};

export const ROLES_MAPPING_ROUTER = {
  ROLE_ADMIN: "admin",
  ROLE_SUBADMIN: "sub_admin",
  ROLE_USER: "user",
  ROLE_CHAIN: "chain",
  ROLE_STORE: "store",
};

export const ACCESS_TOKEN_KEY = "accesstoken";

export const ERROR_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  NOT_AUTHENTICATION: 401,
  Forbidden: 403,
  NotFound: 404,
  GeneralServerRrror: 500,
  Unavailable: 503,
};

export const ERROR_MESSAGES = {
  ERROR_LOGIN_POST_USERINACTIVE: "ERROR-LOGIN-POST-USERINACTIVE",
};

export const DATE_FORMAT = "YYYY/MM/DD";
export const GANTT_CHART_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_JAPAN_FORMAT = "YYYY年MM月DD日, HH:mm:ss";
export const IMAGE_FILE_TYPE_PRE = "image/";
export const MAX_TOTAL_FILE_SIZE = 10000000; // 10Mb
export const MAX_MANUAL_FILE_SIZE = 100000000; // 100Mb
export const TIMEZONE_JAPAN = "Asia/Tokyo";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const DATE_AND_HOUR_MINUTES_FORMAT = "YYYY/MM/DD HH:mm";
export const TIME_FORMAT = "HH:mm:ss";
export const HOUR_MINUTES_FORMAT = "HH:mm";
export const MONTH_FORMAT = "YYYY/MM";

export const NOTIFY = {
  CHANGE_OTA: "下記サイトのパスワードが更新されました",
  NEW_COMMENT: "新しいコメントが届きました",
  CHANE_STATUS: "ステータスが変更されました",
};

export const KEYS = {
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  MORE: "MORE",
};

export const OTA_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const API_PLAN_OTA_STATUS = {
  0: "失敗",
  1: "成功",
};

export const ONE_DAY_MILISECOND = 1000 * 60 * 60 * 24;

export const X_REQUESTED_STOREID = "X-Requested-StoreId";
export const X_REQUESTED_CHAINID = "X-Requested-ChainId";

export const OTA_FIELD_NAME = "ITEMID10010";
export const AVAILABLE_FIELD_NAME = "ITEMID10014";
export const POST_TIME_FIELD_NAME = "ITEMID10015";
export const TARGET_PLAN_FIELD_NAME = "ITEMID10079";

export const PLANID_VISIBLE_TARGET_PLAN = [10001, 10002];

export const ITEM_CATEGORY_PLAN_IGNORE = [10030];

export const otaValues = {
  じゃらん: "JARAN_TAB",
  楽天: "RAKUTEN_TAB",
  るるぶ: "RURUBU_TAB",
  予約プロ: "YOYAKU_PRO_TAB",
  一休: "IKKYU_TAB",
  ダイレクトイン: "DAIREKUTOIN_TAB",
};

export const otaNames = [
  "JARAN_TAB",
  "RAKUTEN_TAB",
  "RURUBU_TAB",
  "YOYAKU_PRO_TAB",
  "IKKYU_TAB",
  "DAIREKUTOIN_TAB",
];

export const ignoreChangedKeys = [
    'attachs',
    'assigneeId',
    'childs',
    'commentType',
    'comments',
    'deliveryDate',
    'startDate',
    'dueDate',
    'personInCharge',
    'estTime',
    'estPoint',
    'status',
    'taskId',
    'directorId',
    'actualTime',
    'registerPersonId',
    'registerDate',
    'storeId',
    'parentTaskId',
    'visible',
    'planId',
    'assignedMember',
    'doingUser',
    'doneUser',
    'storeName',
    'oldTask',
    'extensionImages',
];

export const changedKeys = {
    categoryId: "page.taskAddEdit.category",
    title: "page.taskAddEdit.title",
    note: "page.taskAddEdit.remarks",
    priority: "page.taskAddEdit.priority",
    show: "page.taskAddEdit.isVisible",
    executedTeam: "page.taskAddEdit.executedTeam",
    workSheet: "page.taskAddEdit.workSheet",
    fixedDeadline: "page.taskAddEdit.fixedDeadline",
    storeIds: "page.taskAddEdit.store",
    titlePrice: "page.TemPlate.titlePriceDefault",
    priceChildren: "page.TemPlate.titlePriceChildren",
    cancellationPolicy: "page.TemPlate.cancellationPolicy",
    subCategoryId: "page.taskAddEdit.subCategory",
    taskContent: "page.taskList.taskContent",
    supplement: "page.taskAddEdit.supplement",
    ITEMID10010: "page.taskAddEdit.otaItem.targetOTA",
    ITEMID10011: "page.taskAddEdit.basicPlanInfo.title.roomUse",
    ITEMID10012: "page.taskAddEdit.basicPlanInfo.title.planTitle",
    ITEMID10013: "page.taskAddEdit.explanatoryText.title.planContent",
    ITEMID10014: "page.taskAddEdit.basicPlanInfo.title.availableTime",
    ITEMID10015: "page.taskAddEdit.basicPlanInfo.title.postTime",
    ITEMID10016: "page.taskAddEdit.basicPlanInfo.title.endTime",
    ITEMID10017: "page.taskAddEdit.basicPlanInfo.title.checkIn",
    ITEMID10018: "page.taskAddEdit.basicPlanInfo.title.checkOut",
    ITEMID10019: "page.taskAddEdit.basicPlanInfo.title.minConsecutiveNight",
    ITEMID10020: "page.taskAddEdit.basicPlanInfo.title.maxConsecutiveNight",
    ITEMID10021: "page.taskAddEdit.basicPlanInfo.title.mealCondition",
    ITEMID10022: "page.taskAddEdit.basicPlanInfo.title.paymentMethod",
    ITEMID10023: "page.taskAddEdit.explanatoryText.title.questionsToBooker",
    ITEMID10024: "page.taskAddEdit.explanatoryText.title.chargeSpecialNote",
    ITEMID10025: "page.taskAddEdit.basicPlanInfo.title.reservationStartDate",
    ITEMID10027: "page.taskAddEdit.basicPlanInfo.title.rewardPoints",
    ITEMID10029: "page.taskAddEdit.explanatoryText.title.childFeeSpecialNote",
    ITEMID10030: "page.taskAddEdit.photoGalleryModification.taskDetail.title",
    ITEMID10031: "page.taskAddEdit.cost.patternDefault.title",
    ITEMID10032: "page.taskAddEdit.basicPlanInfo.title.bathTax",
    ITEMID10033: "page.taskAddEdit.explanatoryText.title.packagePlanContent",
    ITEMID10034: "page.taskAddEdit.basicPlanInfo.title.planRank",
    ITEMID10035: "page.taskAddEdit.jaran.package.title",
    ITEMID10036: "page.taskAddEdit.jaran.informationServices.title",
    ITEMID10037: "page.taskAddEdit.jaran.lodgingLogPost.title",
    ITEMID10038: "page.taskAddEdit.rakuten.salesDestination.title",
    ITEMID10039: "page.taskAddEdit.rakuten.inboundSales.title",
    ITEMID10040: "page.taskAddEdit.rakuten.planFeatures.title",
    ITEMID10041: "page.taskAddEdit.rakuten.rakutenSuperPoint.title",
    ITEMID10042: "page.taskAddEdit.rakuten.limitedSales.title",
    ITEMID10043: "page.taskAddEdit.rurubu.limitMenWomen.title",
    ITEMID10044: "page.taskAddEdit.youaku.planForm.title",
    ITEMID10045: "page.taskAddEdit.youaku.planIntroduction1.title",
    ITEMID10046: "page.taskAddEdit.youaku.planIntroduction2.title",
    ITEMID10047: "page.taskAddEdit.youaku.mealIntroduction.title",
    ITEMID10048: "page.taskAddEdit.youaku.installationFee.title",
    ITEMID10049: "page.taskAddEdit.ikkyu.designateSalesDestination.title",
    ITEMID10050: "page.taskAddEdit.ikkyu.planCode.title",
    ITEMID10051: "page.taskAddEdit.dairekutoin.rangeOfCharges.title",
    ITEMID10052: "page.taskAddEdit.roomCreationInstructions.roomTypeName.title",
    ITEMID10053: "page.taskAddEdit.roomCreationInstructions.amountPeople.title",
    ITEMID10054: "page.taskAddEdit.roomCreationInstructions.roomTypeExplanation.title",
    ITEMID10055: "page.taskAddEdit.roomCreationInstructions.roomSize.title",
    ITEMID10056: "page.taskAddEdit.roomCreationInstructions.roomType.title",
    ITEMID10057: "page.taskAddEdit.roomCreationInstructions.roomEquipment.title",
    ITEMID10058: "page.taskAddEdit.roomCreationInstructions.bedInformation.title",
    ITEMID10059: "page.taskAddEdit.rakuten.roomChargeFormat.title",
    ITEMID10060: "page.taskAddEdit.rakuten.publicFlag.title",
    ITEMID10061: "page.taskAddEdit.rakuten.id.title",
    ITEMID10062: "page.taskAddEdit.rakuten.paymentMethod.title",
    ITEMID10063: "page.taskAddEdit.jaran.grade.title",
    ITEMID10064: "page.taskAddEdit.jaran.view.title",
    ITEMID10065: "page.taskAddEdit.jaran.bedding.title",
    ITEMID10066: "page.taskAddEdit.jaran.acceptanceConditions.title",
    ITEMID10067: "page.taskAddEdit.basicRoomProvided.title",
    ITEMID10068: "page.taskAddEdit.rurubu.guestRoomUsage.title",
    ITEMID10069: "page.taskAddEdit.ikkyu.sleepingWithChildren.title",
    ITEMID10070: "page.taskAddEdit.ikkyu.romCode.title",
    ITEMID10071: "page.taskAddEdit.other.image.title",
    ITEMID10072: "page.taskAddEdit.photoGalleryModification.caption.title",
    ITEMID10073: "page.taskAddEdit.photoGalleryModification.displaySettingAndArrangement.title",
    ITEMID10074: "page.taskAddEdit.other.targetPeriod.title",
    ITEMID10075: "page.taskAddEdit.photoGalleryModification.imageDataStoreUrl.title",
    ITEMID10076: "page.taskAddEdit.other.editingRange.title",
    ITEMID10077: "page.taskAddEdit.other.targetArea.title",
    ITEMID10078: "page.taskAddEdit.other.date.title",
    ITEMID10079: "page.taskAddEdit.other.targetPlan.title",
    ITEMID10080: "page.taskAddEdit.roomCreationInstructions.amountPeople.title",
    ITEMID10081: "page.taskAddEdit.other.referencePage.title",
    ITEMID10082: "page.taskAddEdit.basicPlanInfo.title.ReservablePeriod",
    referencePlanOta: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.referencePlanOta",
    targetOta: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.targetOta",
    roomTypeToLink: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.roomTypeToLink",
    postPeriod: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.postPeriod",
    salePeriod: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.salePeriod",
    pricingSetting: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.pricingSetting",
    extraImage: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.extraImage",
    caption: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.caption",
    dpSetting: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.dpSetting",
    otherSetting: "page.taskAddEdit.extraField.parentCategory10000.subCategory10000.otherSetting",
    type: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.type",
    targetOtaExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.targetOtaExtension",
    postPeriodExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.postPeriodExtension",
    stayPeriodExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.stayPeriodExtension",
    planNameAndPlanIdExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.planNameAndPlanIdExtension",
    entryExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.entryExtension",
    surveyResponseExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.surveyResponseExtension",
    imageExtension: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.imageExtension",
    referencePlanOtaPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.referencePlanOtaPlanCreating",
    targetOtaPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.targetOtaPlanCreating",
    roomTypeToLinkPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.roomTypeToLinkPlanCreating",
    postPeriodPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.postPeriodPlanCreating",
    salePeriodPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.salePeriodPlanCreating",
    pricingSettingPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.pricingSettingPlanCreating",
    imagePlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.imagePlanCreating",
    captionPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.captionPlanCreating",
    dpSettingPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.dpSettingPlanCreating",
    entryPlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.entryPlanCreating",
    surveyResponsePlanCreating: "page.taskAddEdit.extraField.parentCategory10000.subCategory10002.surveyResponsePlanCreating",
    targetPlan: "page.taskAddEdit.extraField.parentCategory10001.targetPlan",
    detailChange: "page.taskAddEdit.extraField.parentCategory10001.detailChange",
    importantPoint: "page.taskAddEdit.extraField.parentCategory10001.importantPoint",
    taskFormat: "page.taskAddEdit.taskFormat",
}

export const TYPE_ACTIVE_COPY_TASK = {
  ENABLED : true,
  STOPPED : false
}

export const TYPE_WEEKEND = {
  MOVE_BACK : '-',
  MOVE_FORWARD : '+'
}

export const TYPE_MONTH = {
  EVERY : '1',
  SPECIFIED : '2'
}

export const searchSelect = {
  showSearch: true,
  optionFilterProp: "children",
};

export const MODE_SCREEN = {
  NEW : 'new',
  EDIT : 'edit',
  COPY : 'clone',
}

export const TASK_FORMAT = {
    ORIGIN : '清書',
    DRAFT : '下書き'
}

export const TYPE_SORT = {
    asc: '0',
    desc: '1',
};
