import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import {apiCrawlRoomService, taskService} from "@iso/services";
import actions from "@iso/redux/apiCrawlRoom/actions";

function* getListCrawlRoom() {
  yield takeEvery(actions.GET_CRAWL_ROOM_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      keyword,
      keywordTypes,
      otaIds,
      createdUsers,
      createdAtStart,
      createdAtEnd,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.getListCrawlRoom, {
        storeIds,
        keyword,
        keywordTypes,
        otaIds,
        createdUsers,
        createdAtStart,
        createdAtEnd,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_CRAWL_ROOM_SUCCESS,
        apiCrawlRooms: response ? response.apiCrawlRooms : [],
        total: response ? response.total : 0
      });
      yield resolve(response.apiCrawlRooms);
    } catch (e) {
      yield put({
        type: actions.GET_CRAWL_ROOM_ERROR,
        error: e.message,
      });
    }
  });
}


function* crawlRoom() {
  yield takeEvery(actions.CRAWL_ROOM_REQUEST, function* ({ payload }) {
    const {
      storeId,
      keyword,
      keywordType,
      otaId,
      typeCrawl,
      crawlRoomId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoom, {
        storeId,
        keyword,
        keywordType,
        otaId,
        typeCrawl,
        crawlRoomId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER_REQUEST, function* ({ payload }) {
    const { list_role } = payload;
    try {
      const [assignees] = yield all([
        call(taskService.getAssignees, { list_role }),
      ]);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        listUser: assignees ? assignees.accounts : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_USER_ERROR,
        error: e.message,
      });
    }
  });
}

function* crawlRoomAll() {
  yield takeEvery(actions.CRAWL_ROOM_ALL_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoomAll, {
        storeId,
        otaId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_ALL_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_ALL_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* crawlRoomDetails() {
  yield takeEvery(actions.CRAWL_ROOM_DETAILS_REQUEST, function* ({ payload }) {
    const {
      arrayApiRoomOtaId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlRoomService.crawlRoomDetails, {
        arrayApiRoomOtaId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_ROOM_DETAILS_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_ROOM_DETAILS_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListCrawlRoom),
    fork(crawlRoom),
    fork(getListUser),
    fork(crawlRoomAll),
    fork(crawlRoomDetails),
  ]);
}
