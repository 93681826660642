import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {helpService} from "@iso/services";
import actions from "@iso/redux/help/actions";

function* getStoreData() {
  yield takeEvery(actions.GET_DATA_HELP_STORE_REQUEST, function* ({ payload }) {
    let { storeId } = payload;
    try {
      const store = yield call(helpService.getDataStoresHelp, { storeId });
      yield put({
        type: actions.GET_DATA_HELP_STORE_SUCCESS,
        store: store,
      });
    } catch (e) {
      yield put({
        type: actions.GET_DATA_HELP_ERROR,
        error: e.response,
      });
    }
  });
}

function* getChainData() {
  yield takeEvery(actions.GET_DATA_HELP_CHAIN_REQUEST, function* ({ payload }) {
    let { chainId } = payload;
    try {
      const res = yield call(helpService.getDataChainHelp, { chainId });
      yield put({
        type: actions.GET_DATA_HELP_CHAIN_SUCCESS,
        stores: res,
      });
    } catch (e) {
      yield put({
        type: actions.GET_DATA_HELP_ERROR,
        error: e.response,
      });
    }
  });
}

function* getManualFileDetail() {
  yield takeEvery(actions.GET_FILE_REQUEST, function* () {
    try {
      const fileExist = yield call(helpService.getManualFileDetail);
      yield put({
        type: actions.GET_FILE_SUCCESS,
        fileExist,
      });
    } catch (error) {
      yield put({
        type: actions.GET_FILE_ERROR,
        error: error.response,
      });
    }
  });
}

function* createAggregateData() {
  yield takeEvery(actions.GET_AGGREGATE_REQUEST, function* ({ payload }) {
    const { fromDate, toDate, site, numberOfPeople, resolve, reject } = payload;
    try {
      const report = yield call(helpService.createDataAggregateHelp, { fromDate, toDate, site, numberOfPeople });
      yield put({ type: actions.GET_AGGREGATE_SUCCESS });
      yield resolve(report);
    } catch (e) {
      yield put({ type: actions.GET_AGGREGATE_ERROR, error: e.message });
      yield reject(e);
    }
  });
}

function* getFileReport() {
  yield takeEvery(actions.GET_FILE_REPORT_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const report = yield call(helpService.getFileReport);
      yield put({
        type: actions.GET_FILE_REPORT_SUCCESS
      });
      yield resolve(report);
    } catch (error) {
      yield put({
        type: actions.GET_FILE_REPORT_ERROR,
        error: error.response,
      });
      yield reject(error);
    }
  });
}

function* createRankingCandeoAggregateData() {
  yield takeEvery(actions.GET_RANKING_CANDEO_AGGREGATE_REQUEST, function* ({ payload }) {
    const { fromDate, toDate, site, numberOfPeople, resolve, reject } = payload;
    try {
      const report = yield call(helpService.createDataRankingCandeoAggregateHelp, { fromDate, toDate, site, numberOfPeople });
      yield put({ type: actions.GET_RANKING_CANDEO_AGGREGATE_SUCCESS });
      yield resolve(report);
    } catch (e) {
      yield put({ type: actions.GET_RANKING_CANDEO_AGGREGATE_ERROR, error: e.message });
      yield reject(e);
    }
  });
}

function* getFileRankingCandeoReport() {
  yield takeEvery(actions.GET_FILE_RANKING_CANDEO_REPORT_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const report = yield call(helpService.getFileRankingCandeoReport);
      yield put({
        type: actions.GET_FILE_RANKING_CANDEO_REPORT_SUCCESS
      });
      yield resolve(report);
    } catch (error) {
      yield put({
        type: actions.GET_FILE_RANKING_CANDEO_REPORT_ERROR,
        error: error.response,
      });
      yield reject(error);
    }
  });
}

function* createRankingAggregateData() {
  yield takeEvery(actions.GET_RANKING_AGGREGATE_REQUEST, function* ({ payload }) {
    const { fromDate, toDate, site, numberOfPeople, resolve, reject } = payload;
    try {
      const report = yield call(helpService.createDataRankingAggregateHelp, { fromDate, toDate, site, numberOfPeople });
      yield put({ type: actions.GET_RANKING_AGGREGATE_SUCCESS });
      yield resolve(report);
    } catch (e) {
      yield put({ type: actions.GET_RANKING_AGGREGATE_ERROR, error: e.message });
      yield reject(e);
    }
  });
}

function* getFileRankingReport() {
  yield takeEvery(actions.GET_FILE_RANKING_REPORT_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const report = yield call(helpService.getFileRankingReport);
      yield put({
        type: actions.GET_FILE_RANKING_REPORT_SUCCESS
      });
      yield resolve(report);
    } catch (error) {
      yield put({
        type: actions.GET_FILE_RANKING_REPORT_ERROR,
        error: error.response,
      });
      yield reject(error);
    }
  });
}

function* createSolareAggregateData() {
  yield takeEvery(actions.GET_SOLARE_AGGREGATE_REQUEST, function* ({ payload }) {
    const { site, orderType, resolve, reject } = payload;
    try {
      const report = yield call(helpService.createDataSolareAggregateHelp, { site, orderType });
      yield put({ type: actions.GET_SOLARE_AGGREGATE_SUCCESS });
      yield resolve(report);
    } catch (e) {
      yield put({ type: actions.GET_SOLARE_AGGREGATE_ERROR, error: e.message });
      yield reject(e);
    }
  });
}

function* getFileSolareReport() {
  yield takeEvery(actions.GET_FILE_SOLARE_REPORT_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const report = yield call(helpService.getFileSolareReport);
      yield put({
        type: actions.GET_FILE_SOLARE_REPORT_SUCCESS
      });
      yield resolve(report);
    } catch (error) {
      yield put({
        type: actions.GET_FILE_SOLARE_REPORT_ERROR,
        error: error.response,
      });
      yield reject(error);
    }
  });
}

function* createOrientalAggregateData() {
  yield takeEvery(actions.GET_ORIENTAL_AGGREGATE_REQUEST, function* ({ payload }) {
    const { site, resolve, reject } = payload;
    try {
      const report = yield call(helpService.createDataOrientalAggregateHelp, { site });
      yield put({ type: actions.GET_ORIENTAL_AGGREGATE_SUCCESS });
      yield resolve(report);
    } catch (e) {
      yield put({ type: actions.GET_ORIENTAL_AGGREGATE_ERROR, error: e.message });
      yield reject(e);
    }
  });
}

function* getFileOrientalReport() {
  yield takeEvery(actions.GET_FILE_ORIENTAL_REPORT_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const report = yield call(helpService.getFileOrientalReport);
      yield put({
        type: actions.GET_FILE_ORIENTAL_REPORT_SUCCESS
      });
      yield resolve(report);
    } catch (error) {
      yield put({
        type: actions.GET_FILE_ORIENTAL_REPORT_ERROR,
        error: error.response,
      });
      yield reject(error);
    }
  });
}
export default function* rootSaga() {
  yield all([
      fork(getChainData),
    fork(getStoreData),
    fork(getManualFileDetail),
    fork(createAggregateData),
    fork(getFileReport),
    fork(getFileRankingCandeoReport),
    fork(createRankingCandeoAggregateData),
    fork(createRankingAggregateData),
    fork(getFileRankingReport),
    fork(createSolareAggregateData),
    fork(getFileSolareReport),
    fork(createOrientalAggregateData),
    fork(getFileOrientalReport),
  ]);
}
