import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class HelpService extends BaseService {
  getDataChainHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_HELP_CHAIN_INFO, {
      params: { ...data },
    });
  };

  getDataStoresHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.GET_HELP_STORE_INFO, {
      params: { ...data },
    });
  };

  getManualFileDetail = () => {
    return this.axios.get(API_ENDPOINTS.HELP_MANUAL_FILE_DETAIL);
  };

  getManualFile = ({...data}) => {
    return this.axios.get(API_ENDPOINTS.HELP_MANUAL_FILE, {
      params: { ...data },
    });
  };

  removeManual = ({ helpId }) => {
    return this.axios.get(
        API_ENDPOINTS.HELP_REMOVE_MANUAL_FILE
            .replace("{helpId}", helpId)
    );
  };

  saveManual = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.HELP_SAVE_MANUAL, {...data});
  };

  createDataAggregateHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_HOTEL, {
      params: { ...data },
    });
  };

  getFileReport = () => {
    return this.axios.get(API_ENDPOINTS.HELP_GET_FILE_REPORT);
  };

  createDataRankingCandeoAggregateHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_RANKING_CANDEO_HOTEL, {
      params: { ...data },
    });
  };

  getFileRankingCandeoReport = () => {
    return this.axios.get(API_ENDPOINTS.HELP_GET_RANKING_CANDEO_FILE_REPORT);
  };

  createDataRankingAggregateHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_RANKING_HOTEL, {
      params: { ...data },
    });
  };

  getFileRankingReport = () => {
    return this.axios.get(API_ENDPOINTS.HELP_GET_RANKING_FILE_REPORT);
  };

  createDataSolareAggregateHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_SOLARE_HOTEL, {
      params: { ...data },
    });
  };

  getFileSolareReport = () => {
    return this.axios.get(API_ENDPOINTS.HELP_GET_SOLARE_FILE_REPORT);
  };

  createDataOrientalAggregateHelp = ({ ...data }) => {
    return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_ORIENTAL_HOTEL, {
      params: { ...data },
    });
  };

  getFileOrientalReport = () => {
    return this.axios.get(API_ENDPOINTS.HELP_GET_ORIENTAL_FILE_REPORT);
  };
}
